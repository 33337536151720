import React from 'react'
import Heading from '../../../components/shared/heading'
import Text from '../../../components/shared/text'
import { SectionTitleWrap } from "../../company/join-our-team/join-our-team.stc"
import { AboutSectionWrap, SectionInnerLeft, SectionInnerRight } from '../web-development/about.stc'
import PropTypes from "prop-types"
import { StaticImage } from 'gatsby-plugin-image'
import { navigate } from "gatsby"
import Button from "../../../components/shared/button";

//queries the serviceswebdata.json file
const Section = ({ HeadingStyle, SubHeadingStyle, Paragraph, HeadingStyleServies }) => {
    // const servicesQueryData = useStaticQuery(graphql `
    //     query ServicesAiDataQuery {
    //         servicesaidataJson(id: {eq: "ai-content"}) {
    //             aiServices {
    //                 title
    //                 classId
    //                 link
    //                 description
    //                 tableHeader
    //                 tableData
    //                 image {
    //                     childImageSharp {
    //                         fluid(maxWidth: 960, quality: 100) {
    //                             ...GatsbyImageSharpFluid_withWebp
    //                         }
    //                     }
    //                 }
    //             }
    //         }
    //     }
    // `)
    // const serviceData = servicesQueryData.servicesaidataJson;
    // const aiServices = serviceData.aiServices;
    // console.log(serviceData.aiServices)

    // const aiContent = aiServices.map((aiService, index) =>
    //     <div className="col-1 offset-1" key={index}>
    //         <a style={{color: "black"}} href={aiService.link}>{aiService.title}</a>
    //     </div>
    // );

    // const content = aiServices.map((aiService, index) =>
    //     <div className="row services" key={index} id={aiService.classId}>
    //         <div className="col-2 offset-1">
    //             <SectionInner  style={{paddingRight: "10vh"}}>
    //                 <SectionTitleWrap>
    //                     <Heading {...HeadingStyleServies}>{aiService.title}</Heading>
    //                     {aiService.description.map((textData, i) => <Text key={`story-text-${i}`} {...Paragraph}>{textData}</Text>)}
    //                 </SectionTitleWrap>
    //             </SectionInner>
    //         </div>
    //         <div className="col-2">
    //             <SectionInner>
    //                 {aiService.image ?
    //                 <Fade right>
    //                     <img src={aiService.image.childImageSharp.fluid.src} alt={aiService.title}/>
    //                 </Fade>
    //                 :
    //                 <Fade right>
    //                     <SectionTitleWrap>
    //                         {aiService.tableHeader && <Heading {...SubHeadingStyle}>{aiService.tableHeader}</Heading> }
    //                         <div className="row">
    //                             {aiService.tableData.map((textData, i) =>
    //                                 <div className="col-1" key={`story-text-${i}`}>
    //                                     <Text {...Paragraph}>{textData}</Text>
    //                                 </div>)
    //                             }
    //                         </div>
    //                     </SectionTitleWrap>
    //                 </Fade>
    //                 }

    //             </SectionInner>
    //         </div>
    //     </div>
    // );

    return (
        <AboutSectionWrap>
            <div className="row service-top-section">
                <SectionInnerLeft>
                    <SectionTitleWrap>
                        <Heading {...HeadingStyle}>Data Consulting</Heading>
                    </SectionTitleWrap>
                </SectionInnerLeft>
                <SectionInnerRight>
                    <StaticImage src={"../../../../src/data/images/Big-data-consult.png"} alt="ai header"/>
                </SectionInnerRight>
            </div>

            <div className="row services">
                <SectionInnerLeft>
                    <SectionTitleWrap>
                        <Heading className="choosing-right-data" style={{ marginBottom: 70 }} {...HeadingStyleServies}>Choosing The Right Data Strategy</Heading>
                    </SectionTitleWrap>
                </SectionInnerLeft>
                <SectionInnerRight>
                    <SectionTitleWrap>
                        <h4>The right data strategy can depend on your organisation size, number of data sources, people and resources.
                        </h4>
                        <Button
                            layout={2}
                            onClick={() => { navigate("/contact") }}
                            style={{ backgroundColor: "green", color: "white" }}
                        >Learn More</Button>
                        {/*<Text {...Paragraph}>*/}
                        {/*</Text>*/}
                    </SectionTitleWrap>
                </SectionInnerRight>
            </div>

            <div className="row services">
                <SectionInnerLeft>
                    <SectionTitleWrap>
                        <Heading className="start-right-path" style={{ paddingRight: 70, paddingBottom: 250 }} {...HeadingStyleServies}>Start on the Right Path</Heading>
                    </SectionTitleWrap>
                </SectionInnerLeft>
                <SectionInnerRight>
                    <SectionTitleWrap>
                        <h3>
                            Understanding Your strategies, objectives and technologies
                        </h3>
                        <Text {...Paragraph}>
                            Developing data insights is a step by step process requiring a holistic understanding of your source systems, objectives and strategic goals.
                        </Text>
                        <Text {...Paragraph}>
                            Once a picture of your systems has been established we can help align your data goals and requirements with your business strategy factoring in cost, time and efficiency.
                        </Text>
                        <Text {...Paragraph}>
                            Over the longer term, understanding how data can be pivotal and important process in your organisations success can help set you up for better wins in the the future.
                        </Text>
                        <Button
                            layout={2}
                            onClick={() => { navigate("/contact") }}
                            style={{ backgroundColor: "#4115B7", color: "white" }}
                        >Chat Today</Button>
                    </SectionTitleWrap>
                </SectionInnerRight>
            </div>

            {/*<div className="row services">*/}
            {/*    <div className="col-8">*/}
            {/*        <SectionInner>*/}
            {/*            <img src={require("../../../../src/data/images/webdev.jpg")} alt="ai header"/>*/}
            {/*        </SectionInner>*/}
            {/*    </div>*/}
            {/*</div>*/}

            {/* {content} */}

        </AboutSectionWrap>
    )
}
Section.propTypes = {
    HeadingStyle: PropTypes.object,
    SubHeadingStyle: PropTypes.object,
    Paragraph: PropTypes.object,
    HeadingStyleServies: PropTypes.object,
    aiServiceLeft: PropTypes.object
}

Section.defaultProps = {
    HeadingStyle: {
        fontSize: '60px',
        texttransform: 'capitalize',
        lineHeight: '60px',
        color: '#e4e4e4',
        fontweight: 600,
        responsive: {
            medium: {
                fontSize: '34px',
                lineHeight: 'initial'
            },
            small: {
                fontSize: '35px',
                lineHeight: '35px'
            }
        }
    },
    SubHeadingStyle: {
        fontSize: '30px',
        lineHeight: '30px',
        color: 'primary',
        fontweight: 300,
        responsive: {
            medium: {
                fontSize: '24px',
                lineHeight: 'initial'
            },
            small: {
                fontSize: '25px',
                lineHeight: '35px'
            }
        }
    },
    Paragraph: {
        color: 'black',
        fontSize: '20px'
    },
    HeadingStyleServies: {
        fontSize: '50px',
        texttransform: 'capitalize',
        lineHeight: '50px',
        color: 'primary',
        fontweight: 600,
        responsive: {
            medium: {
                fontSize: '34px',
                lineHeight: 'initial'
            },
            small: {
                fontSize: '35px',
                lineHeight: '35px'
            }
        }
    }
}

export default Section

