import styled from 'styled-components';
import {device} from '../../../theme'

export const AboutSectionWrap = styled.div `
    padding-top: 160px;
    background-color: black;

    .section-pt-md{
        padding-top: 70px;
    }
    .col-1.offset-custom{
        margin-left: 5vw;
    }
    .services{
        padding-top: 15vh;
        padding-bottom: 15vw;
        background-color: white;
        align-items: center;
    }
    .service-top-section{
        margin-bottom: 20vh;
    }

    @media ${device.medium}{
        padding: 70px 0;
        .col-1.offset-1, .col-2.offset-1 {
            max-width: 80vw;
            min-width: 80vw;
            flex: 0;
            margin-left: 10vw;
        }
        .col-1.offset-custom{
            margin-left: 10vw;
        }
        .reverse-on-mobile {
            flex-flow: column-reverse;
        }
        .section-pt-md{
            padding-top: 15px;
        }
        .project-type{
            flex-basis: 50%;
            max-width: 50%;
            width: 50%;
            margin: 30px 0;
        }
        .service-image{
            width: 200px;
        }
        .services {
            .full-engineering {
                margin-bottom: 11vh;
            }
        }
        .services {
            .technology-system {
                margin-bottom: 20vh;
            }
        }
        .services {
            .frontend-web {
                margin-bottom: 17vh;
            }
        }
        .services {
            .react-js {
                margin-bottom: 20vh;
            }
        }
        .services {
            .backend-dev {
                margin-bottom: 22vh;
            }
        }
        .services {
            .backend-infra {
                margin-bottom: 20vh;
            }
        }
        .services {
            .assurance-test {
                margin-bottom: 18vh;
            }
        }
        .services {
            .security-comp {
                margin-bottom: 10vh;
            }
        }
    }
    @media ${device.small}{
        padding: 20vw 0;
        .project-type{
            flex-basis: 100%;
            max-width: 100%;
            width: 100%;
            margin: 0;
            padding: 15px 0;
        }
        .service-image{
            width: 200px;
        }

        .service-top-section{
            margin-top: 8vh;
            .service-top-section-image {
                margin-top: 5vh;
            }
        }

        .services {
            .full-engineering {
                margin-bottom: 20vh;
            }
        }

        .services {
            .technology-system {
                margin-bottom: -20vh;
            }
        }
        .services {
            .frontend-web {
                margin-bottom: -20vh;
            }
        }
        .services {
            .react-js {
                margin-bottom: -20vh;
            }
        }
        .services {
            .backend-dev {
                margin-bottom: 5vh;
            }
        }
        .services {
            .backend-infra {
                margin-bottom: -15vh;
            }
        }
        .services {
            .assurance-test {
                margin-bottom: 15vh;
            }
        }
        .services {
            .security-comp {
                margin-bottom: 15vh;
            }
        }
    }
`;

export const AboutContent = styled.div `
    margin-top: -7px;
    margin-bottom: -18px;
`;

export const ProjectTypeList = styled.ul `
    margin: 0;
    padding-left: 17px;
`;


export const SectionTitle = styled.div `
    margin-top: 10px;
`;

export const SectionSubTitle = styled.div `
    margin-top: 10vh;
    @media ${device.medium}{
        margin-top: 5vh;
    }
    @media ${device.small}{
        margin-top: 1vh;
    }
`;

export const SectionInner = styled.div `
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
`;

export const SectionInnerLeft = styled.div `
    width: 50%;
    padding-left: 10vw;
    padding-right: 2vw;
    padding-bottom: 2vw;
    @media ${device.largest}{
        .report-real-time {
            margin-top: 7vw;
        }
        .ingest-write-data {
            padding-right: 30vw;
        }
        .sync-data-realtime {
            margin-bottom: 10vw;
        }
        .run-scheduled-image {
            max-width: 800px;
            max-height: auto;
        }
    }
    @media ${device.medium}{
        margin-top: 8vw;
    }
    @media ${device.xlarge}{
        .report-real-time {
            margin-top: 12vw;
        }
        .api-service-ingest-image {
            margin-top: 20vw;
        }
        .sync-data-realtime {
            margin-top: 10vw;
        }
    }
    @media ${device.small}{
        width: 100%;
    }
`;

export const SectionInnerRight = styled.div `
    width: 50%;
    padding-right: 10vw;
    @media ${device.largest}{
        .report-realtime-image {
            max-width: 600px;
            max-height: auto;
            margin-top: 4vw;
        }
        .api-service-image {
            max-width: 800px;
            max-height: auto;
            margin-top: 8vw
        }
        .api-service-ingest-image {
            margin-top: 3vw;
        }
        .run-scheduled-image {
            margin-top: 6vw;
        }
    }
    @media ${device.xlarge} {
        .api-service-ingest-image {
           margin-bottom: 5vw;
        }
        .run-scheduled-image {
            margin-top: 4vw;
        }
    }
    @media ${device.medium}{
        margin-top: 8vw;
        .dataEngineering-image {
            margin-top: 2vw;
        }
        .api-service-image {
            margin-bottom: 25vw
        }
        .api-service-ingest-image {
            margin-bottom: 25vw
        }
        .cloud-function-image {
            margin-bottom: 50vw;
        }
        .aws-lambda-image {
            margin-top: -25vw;
        }
        .run-scheduled-image {
            margin-bottom: 24vw;
        }
        .surface-insight-image {
            margin-bottom: 38vw;
        }
        .report-realtime-image {
            margin-bottom: 31vw;
        }
    }
    @media ${device.small}{
        width: 100%;
        padding-left: 10vw;
        .cloudService-image {
            margin-top: 7vw;
        }
        .ecommerce-header {
            margin-top: 6vw;
        }
        .surface-insight-image {
            margin-top: 7vw
        }
        .report-realtime-image {
            margin-top: 7vw
        }
        .cloud-function-image {
            margin-top: 7vw
        }
        .aws-lambda-image {
            margin-top: 7vw
        }
    }
`;





